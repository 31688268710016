import {AdminActionTypes} from './constants'



export const adminResponseSuccess = (actionType ,data)=>({
    type: AdminActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType,data}
})
export const adminResponseError = (actionType ,error)=>({
    type: AdminActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType,error}
})
export const financialYear =(title,description,startdate,enddate,yearstatus)=>({
    type:AdminActionTypes.FINANCIAL_YEAR,
    payload:{title,description,startdate,enddate,yearstatus}
})
export const userManagement =(purchaseprice_rights,
    salesprice_rights,username,name,email,password,roleid,branchid,financialYear,salescategory,salesman,itembrand,category,location,customerGroup,supplierGroup,accounts_rights,groupaccounts_rights,salesman_disabled,location_disabled,accounts_disabled,otherdate_rights)=>({
    type:AdminActionTypes.USER_MANAGEMENT,
    payload:{purchaseprice_rights,salesprice_rights,username,name,email,password,roleid,branchid,financialYear,salescategory,salesman,itembrand,category,location,customerGroup,supplierGroup,accounts_rights,groupaccounts_rights,salesman_disabled,location_disabled,accounts_disabled,otherdate_rights}
})
export const userUpdate =(id,purchaseprice_rights,
    salesprice_rights,username,name,email,password,roleid,branchid,financialYear,salescategory,salesman,itembrand,category,location,customerGroup,supplierGroup,accounts_rights,groupaccounts_rights,salesman_disabled,location_disabled,accounts_disabled,otherdate_rights)=>({
    type:AdminActionTypes.USER_UPDATE,
    payload:{id,purchaseprice_rights,
        salesprice_rights,username,name,email,password,roleid,branchid,financialYear,salescategory,salesman,itembrand,category,location,customerGroup,supplierGroup,accounts_rights,groupaccounts_rights,salesman_disabled,location_disabled,accounts_disabled,otherdate_rights}
})
export const manageRole =(description,rightsdata)=>({
    type:AdminActionTypes.ROLE,
    payload:{description,rightsdata}
})
export const dahboardRights =(userid,rightsdata)=>({
    type:AdminActionTypes.DASHBOARD_RIGHTS,
    payload:{userid,rightsdata}
})
export const manageLoginPage =( themename, file)=>({
    type:AdminActionTypes.MANAGE_LOGIN,
    payload:{themename, file}
})
export const cashCounterRights =(userid,rightsdata)=>({
    type:AdminActionTypes.CASHCOUNTER_RIGHTS,
    payload:{userid,rightsdata}
})
export const empRights =(userid,employeeid)=>({
    type:AdminActionTypes.EMP_RIGHTS,
    payload:{userid,employeeid}
})
export const resetUser = () => ({
    type: AdminActionTypes.RESET,
    payload: {},
});